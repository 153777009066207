import { DeleteOutlined, EditOutlined, EyeOutlined } from "@ant-design/icons";
import { Box } from "@mui/material";
import { DataGrid, GridToolbarQuickFilter, viVN } from "@mui/x-data-grid";
import {
    Col,
    Popconfirm,
    Row,
    Space,
    Switch,
    Typography,
    notification,
} from "antd";
import axios from "axios";
import React, { useEffect, useRef, useState } from "react";

import { useTranslation } from "react-i18next";
import authHeader from "../../../../../api/auth_header";
import "../../../../../assets/css/supplier/holtel/priceRoom/modalPr.css";
import { API_BASE_URL, TIME_DURATION } from "../../../../../constants";
import ModalPrAction from "./ModalPrAction";
import FormActionButtonSecond from "../../../common/formActionButton/FormActionButtonSecond ";
import TableMui from "../../../../layout/TableMui";

const PriceRoom = ({
    dvName,
    dvId,
    isView,
    isDichVuKhac,
    QuickSearchToolbar: QuickSearchToolbarProp,
}) => {
    const { t } = useTranslation();
    const [dataSource, setDataSource] = useState([]);
    const [editingKey, setEditingKey] = useState("");
    const [editingRecord, setEditingRecord] = useState("");
    const [isShow, setIsShow] = useState(false);
    const [action, setAction] = useState(false);
    // const [filteredInfo, setFilteredInfo] = useState({});
    // const [dataSearch, setDataSearch] = useState();
    const isEditing = (record) => record?.key === editingKey;
    const [api, contextHolder] = notification.useNotification();
    const [isReload, setIsReload] = useState(false);
    const edit = (record) => {
        setEditingRecord(record);
        setIsShow(true);
        setAction(true);
    };
    useEffect(() => {
        if (isReload) getData();
    }, [isReload]);
    useEffect(() => {
        getData();
    }, []);
    const getData = async () => {
        try {
            const response = await axios.post(
                `${API_BASE_URL}ncc/giaphong/getData`,
                {
                    nccThongTinChungId: dvId,
                    isDichVuKhac: isDichVuKhac ? isDichVuKhac : false,
                },
                { headers: authHeader() }
            );

            if (response.status === 200) {
                console.log("Response: ====", response);
                const data = response.data.data;
                const dataLst = [];
                // const dataLstSearch = [];

                data.forEach((item, index) => {
                    const { tranxTimeApDungRequests, ...rest } = item;
                    // dataLstSearch.push({ value: item.id - item.maPhong - item.hangPhong });

                    if (tranxTimeApDungRequests.length > 0) {
                        tranxTimeApDungRequests.forEach((detail) => {
                            dataLst.push({
                                stt: dataLst.length + 1,
                                rowKey: 1 + Math.random() * (100 - 1),
                                ...rest,
                                tranxTimeApDungRequests:
                                    tranxTimeApDungRequests.length,
                                nccGiaPhongId: detail.nccGiaPhongId,
                                idGp: detail.id,
                                thoiGianApDung: `${detail.tuNgay} - ${detail.denNgay}`,
                                ...detail,
                                ids: rest.id,
                                isDouble: true,
                            });
                        });
                    } else {
                        dataLst.push({
                            stt: dataLst.length + 1,
                            rowKey: index,
                            isDouble: false,
                            ...rest,
                        });
                    }
                });
                setIsReload(false);
                // setDataSearch(dataLstSearch);
                setDataSource(dataLst);
            }
        } catch (error) {
            console.error("Error response: ====", error);
            if (error.response?.status === 401) {
                // Handle unauthorized error
            }
        }
    };
    // // const handleChange = (pagination, filters, sorter) => {
    // //     console.log("Various parameters", pagination, filters, sorter);
    // //     setFilteredInfo(filters);
    // // };
    const handleBorderChange = (record, value) => {
        let values = {
            id: record.idBooking,
            trangThaiBooking: value !== true,
            hieuLuc: record.hieuLuc,
        };
        axios({
            method: "post",
            url: API_BASE_URL + "ncc/giaphong/updateStatusTranxTime",
            data: values,
            headers: authHeader(),
        })
            .then(function (response) {
                if (response.status === 200) {
                    api["success"]({
                        message: t("stour"),
                        description:
                            t("edit") + " " + t("noti.success").toLowerCase(),
                        duration: TIME_DURATION,
                    });
                    setIsReload(true);
                } else {
                    api["error"]({
                        message: t("stour"),
                        description:
                            t("edit") +
                            " " +
                            t("noti.error").toLowerCase() +
                            " " +
                            response.data.message,
                        duration: TIME_DURATION,
                    });
                }
            })
            .catch((error) =>
                api["error"]({
                    message: t("stour"),
                    description:
                        t("edit") + " " + t("noti.error").toLowerCase(),
                    duration: TIME_DURATION,
                })
            );

        // setDataSource(newData);
    };
    const viewRecord = (record) => {
        setEditingRecord(record);
        setAction(false);
        setIsShow(true);
    };
    const handleDelete = (record) => {
        let apiData = {
            id: record.ids,
            hieuLuc: false,
        };
        axios({
            method: "post",
            url: API_BASE_URL + "ncc/giaphong/updateStatus",
            data: apiData,
            headers: authHeader(),
        })
            .then(function (response) {
                if (response.status === 200) {
                    api["success"]({
                        message: t("stour"),
                        description:
                            t("delete") + " " + t("noti.success").toLowerCase(),
                        duration: TIME_DURATION,
                    });
                    getData();
                } else {
                    api["error"]({
                        message: t("stour"),
                        description:
                            t("delete") +
                            " " +
                            t("noti.error").toLowerCase() +
                            " " +
                            response.data.message,
                        duration: TIME_DURATION,
                    });
                }
            })
            .catch((error) =>
                api["error"]({
                    message: t("stour"),
                    description:
                        t("delete") + " " + t("noti.error").toLowerCase(),
                    duration: TIME_DURATION,
                })
            );
    };
    const handleCancel = () => {
        setIsShow(false);
        setEditingRecord("");
    };
    const handleFinish = (status, values) => {
        // console.log(JSON.stringify(values));
        let data = {
            ...values,
            id: values.id, // thêm mới để 0, update truyền id đúng vào
        };
        // console.log("data edit=====", data);
        axios({
            method: "post",
            url: API_BASE_URL + "ncc/giaphong/saveData",
            data: data,
            headers: authHeader(),
        })
            .then(function (response) {
                console.log("response=====", response);
                if (response.status === 200 && response.data.code === "00") {
                    api["success"]({
                        message: t("stour"),
                        description:
                            t("edit") + " " + t("noti.success").toLowerCase(),
                        duration: TIME_DURATION,
                    });
                    setIsReload(true);
                    setIsShow(status);
                    setAction(status);
                } else {
                    api["error"]({
                        message: t("stour"),
                        description:
                            t("edit") +
                            " " +
                            t("noti.error").toLowerCase() +
                            ". " +
                            response.data.message,
                        duration: TIME_DURATION,
                    });
                }
            })
            .catch(function (response) {
                console.log("Error response: ====" + response);
                api["error"]({
                    message: t("stour"),
                    description:
                        t("edit") + " " + t("noti.error").toLowerCase(),
                    duration: TIME_DURATION,
                });
            });
    };
    // // const [searchText, setSearchText] = useState("");
    // // const [searchedColumn, setSearchedColumn] = useState("");
    // // const searchInput = useRef(null);
    // // const handleSearch = (selectedKeys, confirm, field) => {
    // //     confirm();
    // //     setSearchText(selectedKeys[0]);
    // //     setSearchedColumn(field);
    // // };
    // // const getColumnSearchProps = (field) => ({
    // //     filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
    // //         <div
    // //             style={{
    // //                 padding: 8,
    // //             }}
    // //             onKeyDown={(e) => e.stopPropagation()}
    // //         >
    // //             <Input
    // //                 ref={searchInput}
    // //                 placeholder={`${t("search")}`}
    // //                 value={selectedKeys[0]}
    // //                 onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
    // //                 onPressEnter={() => handleSearch(selectedKeys, confirm, field)}
    // //                 style={{
    // //                     marginBottom: 8,
    // //                     display: "block",
    // //                 }}
    // //             />
    // //             <Space>
    // //                 <Button
    // //                     type="primary"
    // //                     onClick={() => handleSearch(selectedKeys, confirm, field)}
    // //                     icon={<SearchOutlined />}
    // //                     size="small"
    // //                 >
    // //                     {t("search")}
    // //                 </Button>
    // //                 <Button onClick={() => clearFilters && handleReset(clearFilters)} size="small">
    // //                     {t("action.reset")}
    // //                 </Button>
    // //                 <Button
    // //                     type="link"
    // //                     size="small"
    // //                     onClick={() => {
    // //                         close();
    // //                     }}
    // //                 >
    // //                     {t("close")}
    // //                 </Button>
    // //             </Space>
    // //         </div>
    // //     ),
    // //     filterIcon: (filtered) => (
    // //         <SearchOutlined
    // //             style={{
    // //                 color: filtered ? "#1677ff" : undefined,
    // //             }}
    // //         />
    // //     ),
    // //     onFilter: (value, record) => record[field].toString().toLowerCase().includes(value.toLowerCase()),
    // //     onFilterDropdownOpenChange: (visible) => {
    // //         if (visible) {
    // //             setTimeout(() => searchInput.current?.select(), 100);
    // //         }
    // //     },
    // //     render: (text) =>
    // //         searchedColumn === field ? (
    // //             <Highlighter
    // //                 highlightStyle={{
    // //                     backgroundColor: "#ffc069",
    // //                     padding: 0,
    // //                 }}
    // //                 searchWords={[searchText]}
    // //                 autoEscape
    // //                 textToHighlight={text ? text.toString() : ""}
    // //             />
    // //         ) : (
    // //             text
    // //         ),
    // // });
    // // const maPhongs = new Set();
    // // const stts = new Set();
    // // const hangPhongs = new Set();
    // // useEffect(() => {
    // //     // https://stackblitz.com/edit/react-bubauz?file=index.js
    // //     // https://stackoverflow.com/questions/61285411/ant-design-table-row-merge-based-on-data-instead-of-index-value
    // //     maPhongs.clear();
    // //     stts.clear();
    // //     hangPhongs.clear();
    // // }, [hangPhongs, maPhongs, stts]);
    const columns = [
        {
            headerName: t("stt"),
            field: "stt",
            key: "stt",
            headerAlign: "center",
            align: "center",
        },
        {
            headerName: t("hotel.code"),
            field: "maPhong",
            key: "maPhong",
            flex: 1,
            headerAlign: "center",
            align: "center",
        },
        {
            headerName: t("hotel.roomClass"),
            field: "hangPhong",
            key: "hangPhong",
            flex: 1,
            headerAlign: "center",
            align: "center",
        },
        {
            headerName: t("hotel.priceClass.typeRoom"),
            field: "kieuPhong",
            key: "kieuPhong",
            flex: 1,
            headerAlign: "center",
            align: "center",
        },
        {
            headerName: t("hotel.priceRoom.applicationTime"),
            field: "thoiGianApDung",
            key: "thoiGianApDung",
            width: 200,
            flex: 1,
            headerAlign: "center",
            align: "center",
        },
        {
            headerName: t("hotel.priceClass.giaNet"),
            field: "fitNgayThuongNet",
            key: "fitNgayThuongNet",
            flex: 1,
            headerAlign: "center",
            align: "center",
            valueFormatter: (params) => {
                if (params.value == null) {
                    return "";
                }
                return params.value.toLocaleString();
            },
        },
        {
            headerName: t("hotel.priceClass.giaBan"),
            field: "fitNgayThuongGiaBan",
            key: "fitNgayThuongGiaBan",
            flex: 1,
            headerAlign: "center",
            align: "center",
            valueFormatter: (params) => {
                if (params.value == null) {
                    return "";
                }
                return params.value.toLocaleString();
            },
        },
        {
            headerName: t("hotel.priceClass.giaNet"),
            field: "extraNgayThuongNet",
            key: "extraNgayThuongNet",
            flex: 1,
            headerAlign: "center",
            align: "center",
            valueFormatter: (params) => {
                if (params.value == null) {
                    return "";
                }
                return params.value.toLocaleString();
            },
        },
        {
            headerName: t("hotel.priceClass.giaBan"),
            field: "extraNgayThuongGiaBan",
            key: "extraNgayThuongGiaBan",
            flex: 1,
            headerAlign: "center",
            align: "center",
            valueFormatter: (params) => {
                if (params.value == null) {
                    return "";
                }
                return params.value.toLocaleString();
            },
        },
        {
            headerName: t("hotel.priceClass.giaNet"),
            field: "fitCuoiTuanNet",
            key: "fitCuoiTuanNet",
            flex: 1,
            headerAlign: "center",
            align: "center",
            valueFormatter: (params) => {
                if (params.value == null) {
                    return "";
                }
                return params.value.toLocaleString();
            },
        },
        {
            headerName: t("hotel.priceClass.giaBan"),
            field: "fitCuoiTuanGiaBan",
            key: "fitCuoiTuanGiaBan",
            flex: 1,
            headerAlign: "center",
            align: "center",
            valueFormatter: (params) => {
                if (params.value == null) {
                    return "";
                }
                return params.value.toLocaleString();
            },
        },
        {
            headerName: t("hotel.priceClass.giaNet"),
            field: "extraCuoiTuanNet",
            key: "extraCuoiTuanNet",
            flex: 1,
            headerAlign: "center",
            align: "center",
            valueFormatter: (params) => {
                if (params.value == null) {
                    return "";
                }
                return params.value.toLocaleString();
            },
        },
        {
            headerName: t("hotel.priceClass.giaBan"),
            field: "extraCuoiTuanGiaBan",
            key: "extraCuoiTuanGiaBan",
            flex: 1,
            headerAlign: "center",
            align: "center",
            valueFormatter: (params) => {
                if (params.value == null) {
                    return "";
                }
                return params.value.toLocaleString();
            },
        },
        {
            headerName: t("exchangeRate.currency"),
            field: "loaiTien",
            key: "loaiTien",
            flex: 1,
            headerAlign: "center",
            align: "center",
        },
        {
            headerName: t("status"),
            field: "trangThaiBooking",
            key: "trangThaiBooking",
            flex: 1,
            headerAlign: "center",
            align: "center",
            renderCell: ({ value, row }) => {
                if (value !== undefined) {
                    return (
                        <Switch
                            defaultChecked={value}
                            onChange={() => handleBorderChange(row, value)}
                        />
                    );
                } else {
                    return null;
                }
            },
        },
        {
            headerName: t("action"),
            field: "action",
            key: "x",
            responsive: ["md"],
            renderCell: (record) => {
                const editable = isEditing(record.row);
                return (
                    <Space>
                        <Typography.Link>
                            <EyeOutlined
                                onClick={() => viewRecord(record.row)}
                            />
                        </Typography.Link>
                        <Typography.Link
                            disabled={editingKey !== ""}
                            onClick={() => edit(record.row)}
                        >
                            <EditOutlined style={{}} />
                        </Typography.Link>
                        <Typography.Link>
                            <Popconfirm
                                title={t("deleteTheTask")}
                                description={t("areYouConfirm")}
                                okText={t("delete")}
                                cancelText={t("huy")}
                                onConfirm={() => handleDelete(record.row)}
                            >
                                <DeleteOutlined />
                            </Popconfirm>
                        </Typography.Link>
                    </Space>
                );
            },
            flex: 1,
            headerAlign: "center",
            align: "center",
        },
    ];
    const columnGroupingModel = [
        {
            groupId: t("hotel.priceClass.weekdayPrice"),
            children: [
                {
                    groupId: "Fit",
                    children: [
                        { field: "fitNgayThuongNet" },
                        { field: "fitNgayThuongGiaBan" },
                    ],
                },
                {
                    groupId: "Extra",
                    children: [
                        { field: "extraNgayThuongNet" },
                        { field: "extraNgayThuongGiaBan" },
                    ],
                },
            ],
        },
        {
            groupId: t("hotel.priceClass.weekendPrice"),
            children: [
                {
                    groupId: "Fit",
                    children: [
                        { field: "fitCuoiTuanNet" },
                        { field: "fitCuoiTuanGiaBan" },
                    ],
                },
                {
                    groupId: "Extra",
                    children: [
                        { field: "extraCuoiTuanNet" },
                        { field: "extraCuoiTuanGiaBan" },
                    ],
                },
            ],
        },
    ];
    // const direct = (record, status) => {
    //     edit(record);
    // };
    //
    // const handleReset = (clearFilters) => {
    //     clearFilters();
    //     setSearchText("");
    // };
    // const onSearch = (value) => {
    //     let data = []
    //     for (let i in dataSearch) {
    //         if (dataSearch[i].value.toString().toLowerCase().includes(value.toLowerCase())) {
    //             let item = dataSearch[i].value.split(" - ")
    //             data.push({
    //                 stt: i,
    //                 id: item[0],
    //                 hoVaTen: item[1],
    //                 phongBan: item[2],
    //                 chucVu: item[3],
    //                 sdt: item[4],
    //                 email: item[5]
    //             })
    //         }
    //     }
    //     setDataSource(data)
    // }
    const addData = () => {
        getData();
    };
    const DefaultQuickSearchToolbar = () => {
        return (
            <Box>
                <Row>
                    <Col xs={6} sm={6} md={6} lg={6} xl={6}>
                        <GridToolbarQuickFilter style={{ width: "100%" }} />
                    </Col>
                    <Col xs={4} sm={6} md={8} lg={6} xl={10}></Col>
                    <Col xs={6} sm={6} md={6} lg={12} xl={8}>
                        <FormActionButtonSecond
                            dvId={dvId}
                            dataAdd={addData}
                            setReload={setIsReload}
                            dichVu="hotel.roomPrice"
                            isDichVuKhac={false}
                        />
                    </Col>
                </Row>
            </Box>
        );
    };

    const QuickSearchToolbar =
        QuickSearchToolbarProp || DefaultQuickSearchToolbar;
    return (
        <div>
            {contextHolder}
            <TableMui
                QuickSearchToolbar={QuickSearchToolbar}
                columnGroupingModel={columnGroupingModel}
                columns={columns}
                dataSource={dataSource}
                edit={edit}
            />
            <ModalPrAction
                isOpen={isShow}
                handleCancel={handleCancel}
                handleFinish={handleFinish}
                record={editingRecord}
                action={action}
                dvId={dvId}
            />
        </div>
    );
};
export default PriceRoom;
